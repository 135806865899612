<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { toast } from 'vue-sonner'
import { useAuthStore } from '@/stores/auth'
import { authService } from '@/services/auth'

const router = useRouter()
const route = useRoute()
const authStore = useAuthStore()

const isLoading = ref(false)

const meta = computed(() => router.currentRoute.value.query)

async function onSuccess(response) {
  isLoading.value = true
  try {
    const { credential } = response
    await authService.google({
      credential,
      meta: meta.value,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: window.navigator.language
    })

    authStore.token = '1'
    await authStore.checkAuth()

    const redirectTo = route.query.redirect_to
    if (redirectTo) {
      await router.push(redirectTo)
    } else {
      await router.push({
        name: 'CheckoutPlans',
        query: route.query
      })
    }
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  window.google?.accounts?.id.initialize({
    client_id: import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID,
    callback: onSuccess,
    use_fedcm_for_prompt: true
  })

  google.accounts.id.renderButton(
    document.getElementById('buttonDiv'),
    {
      theme: 'outline',
      type: 'standard',
      size: 'large',
      width: 400
    }
  )

  console.log('Google Auth initialized')
})
</script>

<template>
  <div>
    <div
      id="buttonDiv"
      :style="{
        'color-scheme': 'light',
      }"
      class="flex items-center justify-center px-4"
    />
  </div>
</template>
